import React from "react"
import Layout from "../components/layout"
import "../components/Hero/hero.scss"
import HeroBg from "../images/Retail.jpg"
import Seo from "../components/seo"
function Thanks() {
  return (
    <Layout>
      <div
        className="thanks-hero internal-hero"
        style={{
          display: "flex",
          alignItems: "flex-end",
          minHeight: "350px",
          backgroundImage: `url(${HeroBg})`,
        }}
      >
        <div className="container">
          <div className="title-wrapper">
            <h1>Thank You</h1>
            <p>One of our team will be in touch with you shortly 🙂</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo title="Thank you - Wirehouse Employer Services" />
)
export default Thanks
